import colors from 'constants/colors';

export const fonts = [
  { crimsonPro: 'Crimson Pro' },
  { comicNeue: 'Comic Neue' },
  { cabin: 'Cabin' },
  { oswald: 'Oswald' },
  { ubuntu: 'Ubuntu' },
  { roboto: 'Roboto' },
  { robotoSlab: 'Roboto Slab' },
  { sourceCodePro: 'Source Code Pro' },
  { bitter: 'Bitter' },
  { cairo: 'Cairo' },
  { yanoneKaffeesatz: 'Yanone Kaffeesatz' },
  { teko: 'Teko' },
  { rajdhani: 'Rajdhani' },
  { kalam: 'Kalam' },
];

export const defaultValues = {
  orientation: 'Portrait',
  fontFamily: 'Roboto',
  autoFullScreen: true,
  primaryColor: colors.crimson,
  secondaryColor: colors.ebonyClay,
  backgroundColor: colors.background,
  activeFrameBorderColor: colors.white,
  floatingIcon: {
    url: '',
    color: colors.white,
    image: { url: '', name: '', type: '' },
  },
  backgroundAttachment: {},
  buttonIcon: { url: '', name: '', type: '', color: colors.white },
};

export const CUSTOM_ICONS = [
  {
    name: 'Language button',
    field: 'languageButtonIcon',
  },
  {
    name: 'Close button',
    field: 'closeButtonIcon',
  },
  {
    name: 'Restart story',
    field: 'restartStoryIcon',
  },
  {
    name: 'Backpack',
    field: 'backpackIcon',
  },
  {
    name: 'Journal button',
    field: 'journalIcon',
  },
  {
    name: 'Next button',
    field: 'nextButtonIcon',
  },

  {
    name: 'Back button',
    field: 'backButtonIcon',
  },
];
