export const defaultValues = {
  name: '',
  triggerType: '',
  actionType: '',
  target: {
    page: '',
    frame: '',
  },
  url: '',
  text: '',
  visible: false,
  mandatory: false,
  mandatoryType: '',
};

export const triggerMenu = [
  {
    click: 'On click',
  },
  {
    autofocus: 'Auto focus',
  },
  {
    hover: 'Hover',
  },
];

export const actionOnClickMenu = [
  {
    navigate: 'Navigate to',
  },
  {
    notes: 'Notes',
  },
  {
    'pop-up': 'Show popup',
  },
];

export const actionOnFocusMenu = [
  {
    'pop-up': 'Show popup',
  },
  {
    'web-pop-up': 'Show web popup',
  },
];

export const actionOnHoverMenu = [
  {
    'tool-tip': 'Show tooltip',
  },
];

export const mandatoryMenu = [
  {
    once: 'Only once',
  },
];
